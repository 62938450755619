.project-card {
  display: block;
  text-decoration: none;
  color: inherit;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  overflow: hidden;
  width: 300px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.project-card:hover {
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.card-content {
  padding: 16px;
  text-align: center;
}

.card-image {
  width: 100%;
  height: 180px;
  object-fit: cover;
}

.card-title {
  margin: 16px 0 8px;
  font-size: 1.5rem;
  font-weight: 600;
}

.card-desc {
  font-size: 1rem;
  color: #666;
  line-height: 1.5;
}
